import rightImg from "../../images/splash-img.jpg";
import logoImg from "../../images/logo-app.png";

import TabComponent from "./TabComponent";
import LoginComponent from "./LoginComponent";

const Login = () => {
  return (
    <div>
      {/* Global container */}
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        {/* Card Container */}
        <div className="relative flex flex-col m-6 space-y-10 bg-white rounded-md shadow-2xl md:flex-row md:space-y-0 md:m-0">
          {/* Left Side */}
          <div className="p-6 md:p-20 ">
            <div className="flex items-center justify-center md:mb-15">
              <img src={logoImg} className="w-[100px] mt-20" alt="logo" />
            </div>
            {/* <TabComponent /> */}
            <LoginComponent />
          </div>
          {/* Right Side */}
          <img
            src={rightImg}
            className="w-[600px] h-[700px] hidden md:block rounded-r-md"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
