import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import UserUpdateChild from "./UserUpdateChild";
import { useLocation } from "react-router-dom";

const UserUpdate = () => {
  const data = useLocation();
  const userRecord = data.state;
  return (
    <MainTemplate>
      <CardBackground>
        <UserUpdateChild userRecord={userRecord} />
      </CardBackground>
    </MainTemplate>
  );
};

export default UserUpdate;
