import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import { useLocation } from "react-router-dom";

import EventTypeListChild from "./EventTypeListChild";

const EventTypeList = () => {
  let rowdata = useLocation();
  let guestRecord = rowdata.state;
  return (
    <MainTemplate>
      <CardBackground>
        <EventTypeListChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default EventTypeList;
