import React from "react";
import { useNavigate } from "react-router-dom";

const MainAdminComponent = ({ setMenuState, menuState }) => {
  let navigate = useNavigate();
  return (
    <>
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/guests");
        }}
      >
        Guest List
      </button>
      {/* <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/event/add");
        }}
      >
        Event Registration
      </button> */}
      {/* <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/events");
        }}
      >
        Events List
      </button> */}
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/etype");
        }}
      >
        Event Types
      </button>
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/elocations");
        }}
      >
        Venue List
      </button>
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/services");
        }}
      >
        Services
      </button>
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/users");
        }}
      >
        Users Management
      </button>
    </>
  );
};

export default MainAdminComponent;
