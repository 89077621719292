import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import ServiceUpdateChild from "./ServiceUpdateChild";

import { useLocation } from "react-router-dom";

const ServiceUpdate = () => {
  let record = useLocation();
  let currentService = record.state;
  return (
    <MainTemplate>
      <CardBackground>
        <ServiceUpdateChild currentService={currentService} />
      </CardBackground>
    </MainTemplate>
  );
};

export default ServiceUpdate;
