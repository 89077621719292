import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import EventUpdateChild from "./EventUpdateChild";
import { useLocation } from "react-router-dom";

const EventUpdate = () => {
  let rowdata = useLocation();
  let guestRecord = rowdata.state.guest;
  let eventRecord = rowdata.state.event;
  return (
    <MainTemplate>
      <CardBackground>
        <EventUpdateChild guestRecord={guestRecord} eventRecord={eventRecord} />
        {/* {JSON.stringify(eventRecord)} */}
      </CardBackground>
    </MainTemplate>
  );
};

export default EventUpdate;
