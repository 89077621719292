import React, { useState, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import ClientCaptcha from "react-client-captcha";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const LoginComponent = () => {
  let navigate = useNavigate();
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [state, setState] = useState({ captchaCode: "", valid: "a" });
  //const { captchaCode } = state;
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  const [captchaWidth, setCaptchaWidth] = useState(350);

  let usernameRef = useRef();
  let passwordRef = useRef();
  let captchaRef = useRef();

  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };

  const checkLogin = async (email, password) => {
    console.log(process.env.REACT_APP_API_URL);
    const credentials = { email, password };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/login`,
      credentials
    );

    return response;
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    //alert(usernameRef.current.value + " pass: " + passwordRef.current.value);
    if (state.valid !== state.captchaCode) {
      toast.error("Unable to login, Bad Captcha");
      captchaRef.current.focus();
      return;
    }

    if (usernameRef.current.value && passwordRef.current.value) {
      try {
        const response = await checkLogin(
          usernameRef.current.value,
          passwordRef.current.value
        );
        //console.log(JSON.stringify(await response));
        localStorage.setItem("token", response.data.authToken);

        setInterval(checkToken, 1000 * 60 * 10);
        const user = jwt_decode(response.data.authToken);
        setUserStatus({
          ...userStatus,
          user: jwt_decode(response.data.authToken),
          handleLogout: () => {
            localStorage.removeItem("token");
            sessionStorage.removeItem("user-details");
            setUserStatus({ ...userStatus, user: null });
            navigate("/login", { replace: true });
          },
        });
        if (user.role === "ADMIN_ACCESS") {
          navigate("/guests", { replace: true });
        } else {
          navigate("/guest", { replace: true });
        }

        toast.success("Logged in successfully");
      } catch (error) {
        toast.error("Unable to login, Bad username or password");
      }
    }
  };

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        // token has expired, do something here (e.g. log out the user)
        localStorage.removeItem("token");
        sessionStorage.removeItem("user-details");
        setUserStatus({ ...userStatus, user: null });
        navigate("/login", { replace: true });
      }
    }
  };

  return (
    <form onSubmit={(e) => handleLoginSubmit(e)}>
      <div className="mt-4">
        <input
          className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
          type="email"
          id="emailaddress"
          required
          placeholder="Enter your email"
          autoComplete="new-password"
          ref={usernameRef}
        />
      </div>
      <div className="mt-3">
        <input
          className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
          type="password"
          id="password"
          required
          placeholder="Password"
          autoComplete="new-password"
          ref={passwordRef}
        />
      </div>
      <div className="mt-3">
        <div className="md:flex md:flex-row">
          <div className="w-full">
            <ClientCaptcha
              //fontFamily={"'Times New Roman', serif"}
              captchaCode={setCode}
              width={150}
              className="rounded-md"
            />
          </div>
          &nbsp;
          <div className="w-full">
            <input
              type="text"
              placeholder="Enter Captcha"
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              onChange={(e) => setState({ ...state, valid: e.target.value })}
              name="valid"
              ref={captchaRef}
              onKeyPress={(e) => e.key === "Enter" && handleLoginSubmit(e)}
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between mt-3">
        <div>
          <NavLink
            to=""
            className="font-sans text-sm font-light text-gray-400 hover:text-gray-700"
          >
            Forgot your password ?
          </NavLink>
        </div>
        <div className="ml-auto">
          <button
            className="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-700"
            type="submit"
          >
            Login
          </button>
        </div>
      </div>
    </form>
  );
};

export default LoginComponent;
