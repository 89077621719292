import React, { useState, useEffect } from "react";
import { indian_states } from "../../../components/Data/IndianStates";
import Select from "react-select";
import { useNavigate, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import VenueCard from "./VenueCard";

const VenueDetail = ({
  handleChange,
  setFormData,
  formData,
  setVenueDetail,
}) => {
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [cityOptions, setCityOptions] = useState();
  const [venues, setVenues] = useState();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const indianStates = Object.keys(indian_states);
  const indianStatesOption = indianStates.map((data, index) => {
    return { value: data, label: data };
  });

  useEffect(() => {
    if (formData?.city && formData?.state) {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/elocation/search/${formData?.state}/${formData?.city}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { state: formData?.state, city: formData?.city },
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response?.data?.locations));
          const locations = response?.data?.locations;
          if (!locations.length) {
            toast.error("No Venue at this location");
            setVenues([]);
          } else {
            // toast.success("Venues ");
            setVenues(locations);
          }

          //
        })
        .catch(function (error) {
          //  toast.error(JSON.stringify(error.response.data.message));
          toast.error(error?.response?.data?.message);
          errorLogApi(error, "GuestAddChild", "/guest/add");
          // console.log(error);
        });
    }
  }, [formData?.city]);

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  return (
    <fieldset className="p-3 mx-auto mt-2 ml-5 whitespace-normal border rounded md:w-full">
      <legend className="float-none w-auto p-2 fs-5">Venue Details</legend>
      <div className="max-w-md mx-auto mb-5">
        {/* Content here  */}
        <div className="mt-3">
          <div className="md:flex md:flex-row">
            <div className="md:w-full">
              <Select
                name="state"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    state: selected.value,
                    city: null,
                  });
                  setCityOptions(
                    indian_states[selected.value].map((d, i) => {
                      return { value: d, label: d };
                    })
                  );
                }}
                placeholder={placeHolderText("Select State")}
                options={indianStatesOption}
                classNamePrefix="my-select"
                className={`w-[${inputWidth}] outline-none h-10`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={style}
                value={
                  formData?.state &&
                  indianStatesOption.find((s) => s.value === formData?.state)
                }
              />
            </div>
            <div className="mt-3 md:w-full md:mt-0 md:ml-1">
              <Select
                name="city"
                onChange={dropDownChange}
                options={cityOptions}
                className={`w-[${inputWidth}] outline-none h-10`}
                placeholder={placeHolderText("Select City")}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={
                  formData?.city && {
                    value: formData?.city,
                    label: formData?.city,
                  }
                }
                styles={style}
              />
            </div>
          </div>
          <div className="mt-4">
            {venues?.length > 0 && (
              <VenueCard
                data={venues}
                formData={formData}
                setFormData={setFormData}
                setVenueDetail={setVenueDetail}
              />
            )}
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default VenueDetail;
