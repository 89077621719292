import React from "react";

const ContextMenu = ({ guestRecord, grecord, setMenuOptions, menuOptions }) => {
  const mainMenu = [
    { value: 0, label: "Bride/Groom Details" },
    { value: 1, label: "Event Type Details" },
    { value: 2, label: "Venue Details" },
    { value: 3, label: "Services" },
  ];

  return (
    <div className="max-w-md mx-auto" id="guest_details">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <div className="bg-white divide-y divide-gray-200">
                {/* Title */}
                <div className="grid grid-cols-2 px-6 py-4 bg-slate-500 whitespace-nowrap md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
                  <div className="flex items-center">
                    <div className="text-sm font-medium text-white capitalize">
                      Select Menu
                    </div>
                  </div>
                </div>

                {mainMenu.map((menu, i) => (
                  <div
                    className={`grid grid-cols-2 px-6 py-4 text-gray-500 cursor-pointer whitespace-nowrap md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 hover:text-white hover:bg-red-400 ${
                      menuOptions === menu?.value ? "bg-red-300 text-black" : ""
                    }`}
                    key={i}
                    onClick={() => setMenuOptions(menu?.value)}
                  >
                    <div className="flex items-center">
                      <div className="text-sm">{menu?.label}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContextMenu;
