import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "../../components/Auth/PrivateRoutes";

import Login from "../../pages/Login/Login";
import Home from "../../pages/Home/Home";
import GuestList from "../../pages/Guests/List/GuestList";
import EventList from "../../pages/Events/List/EventList";
import ServiceList from "../../pages/Services/List/ServiceList";
import GuestAdd from "../../pages/Guests/Create/GuestAdd";
import GuestUpdate from "../../pages/Guests/Update/GuestUpdate";
import ServiceAdd from "../../pages/Services/Create/ServiceAdd";
import ServiceUpdate from "../../pages/Services/Update/ServiceUpdate";
import EventAdd from "../../pages/Events/Create/EventAdd";
import EventLocationList from "../../pages/EventLocations/List/EventLocationList";
import EventLocationAdd from "../../pages/EventLocations/Create/EventLocationAdd";
import EventLocationUpdate from "../../pages/EventLocations/Update/EventLocationUpdate";
import EventTypeList from "../../pages/EventTypes/List/EventTypeList";
import EventTypeAdd from "../../pages/EventTypes/Create/EventTypeAdd";
import EventUpdate from "../../pages/Events/Update/EventUpdate";
import EventTypeUpdate from "../../pages/EventTypes/Update/EventTypeUpdate";
import UsersList from "../../pages/Users/List/UsersList";
import UserAdd from "../../pages/Users/Create/UserAdd";
import UserUpdate from "../../pages/Users/Update/UserUpdate";
import MyAccount from "../../pages/Users/Update/MyAccount";

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/" name="Login" element={<Login />} />
      <Route path="/login" name="Login" element={<Login />} />
      <Route element={<PrivateRoutes roleRequired={["ADMIN_ACCESS"]} />}>
        {/* Here Routes for Full Access */}
        <Route path="/home" name="Home" element={<Home />} />
        <Route path="/guests" name="Guest List" element={<GuestList />} />
        <Route path="/guest/add" name="Guest Add" element={<GuestAdd />} />
        <Route
          path="/guest/update"
          name="Guest Update"
          element={<GuestUpdate />}
        />
        <Route
          path="/elocations"
          name="Event Locations"
          element={<EventLocationList />}
        />
        <Route
          path="/elocation/add"
          name="Event Location Add"
          element={<EventLocationAdd />}
        />
        <Route
          path="/elocation/update"
          name="Event Location Update"
          element={<EventLocationUpdate />}
        />
        {/* Event List */}
        <Route path="/events" name="Event List" element={<EventList />} />
        <Route path="/event/add" name="Event Add" element={<EventAdd />} />
        <Route
          path="/event/update"
          name="Event Update"
          element={<EventUpdate />}
        />
        <Route
          path="/etype"
          name="EventType List"
          element={<EventTypeList />}
        />
        <Route
          path="/etype/add"
          name="EventType Add"
          element={<EventTypeAdd />}
        />
        <Route
          path="/etype/update"
          name="EventType Update"
          element={<EventTypeUpdate />}
        />
        <Route path="/services" name="Service List" element={<ServiceList />} />
        <Route
          path="/service/add"
          name="Service Add"
          element={<ServiceAdd />}
        />
        <Route
          path="/service/update"
          name="Service Update"
          element={<ServiceUpdate />}
        />
        <Route path="/users/" name="Users List" element={<UsersList />} />
        <Route path="/user/add" name="User Add" element={<UserAdd />} />
        <Route
          path="/user/update"
          name="User Update"
          element={<UserUpdate />}
        />
        <Route
          path="/user/myaccount"
          name="Profile Update"
          element={<MyAccount />}
        />
      </Route>
      <Route element={<PrivateRoutes roleRequired={["GUEST_ACCESS"]} />}>
        {/* Here the Routes for Guest */}
      </Route>
    </Routes>
  );
};

export default ProtectedRoutes;
