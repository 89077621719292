import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";

import { indian_states } from "../../../components/Data/IndianStates";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import GuestDetails from "./GuestDetails";
import ContextMenu from "./ContextMenu";
import BrideGrooomDetails from "./BrideGrooomDetails";
import EventTypes from "./EventTypes";
import VenueDetail from "./VenueDetail";
import Services from "./Services";

const EventUpdateChild = ({ guestRecord, eventRecord }) => {
  let navigate = useNavigate();

  const [step, setStep] = useState(1);
  /* 
  0 => Bride/Groom Details
  1 => Event Type Details
  2 => Venue Details
  3 => Services
  */
  const [menuOptions, setMenuOptions] = useState(0);

  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [cityOptions, setCityOptions] = useState();
  const [venueDetail, setVenueDetail] = useState();

  const [formData, setFormData] = useState({
    guest_id: guestRecord?._id,
    event_date: null,
    requester_name: null,
    event_name: null,
    bride_name: null,
    groom_name: null,
    selected_events: null,
    special_event_request: null,
    selected_services: null,
    special_service_request: null,
    state: null,
    city: null,
    venue: null,
    venue_price: null,
    services: null,
  });
  const [eventDates, setEventDates] = useState([]);
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const indianStates = Object.keys(indian_states);
  const indianStatesOption = indianStates.map((data, index) => {
    return { value: data, label: data };
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  useEffect(() => {
    setFormData({ ...eventRecord });
  }, []);

  const handleUpdateEvent = () => {
    //e.preventDefault();

    let userToken = localStorage.getItem("token");
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/event/update/${eventRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Event Updated Successfully.");
        navigate("/events", { state: guestRecord });
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "EventUpdateChild", "/event/update");
        // console.log(error);
      });
  };

  const grecord = {
    // name: guestRecord?.first_name + " " + guestRecord?.last_name,
    email: guestRecord?.email,
    mobile: guestRecord?.mobile,
  };

  useEffect(() => {
    if (formData?.selected_events) {
      function getEarliestDate(dateArrayString) {
        const dateArray = JSON.parse(dateArrayString);

        // Sort the array based on the event_date in ascending order
        dateArray?.sort(
          (a, b) => new Date(a?.event_date) - new Date(b.event_date)
        );

        // Return the earliest date
        return dateArray[0]?.event_date;
      }

      setFormData({
        ...formData,
        event_date: getEarliestDate(formData?.selected_events) || eventDates,
      });
    }
    // toast.success("date changed");
  }, [formData?.selected_events, eventDates]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row p-2 text-white bg-gray-500 rounded">
        <div className="flex items-center ml-2 font-bold">Client Name:</div>
        <div className="flex items-center ml-2">
          {guestRecord?.first_name + " " + guestRecord?.last_name} |
        </div>
        <div className="flex items-center ml-2 font-bold">Phone:</div>
        <div className="flex items-center ml-2">{guestRecord?.mobile} |</div>
        <div className="flex items-center ml-2 font-bold">Email:</div>
        <div className="flex items-center ml-2">{guestRecord?.email}</div>
        <div className="ml-auto">
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 mr-4 text-white bg-red-500 border border-white rounded hover:bg-red-700"
          >
            Back
          </button>

          <button
            onClick={handleUpdateEvent}
            className="px-4 py-2 text-white bg-blue-500 border border-white rounded hover:bg-blue-700"
          >
            Update Event
          </button>
        </div>
      </div>

      <div className="flex flex-row w-full ">
        <div className="flex flex-col mt-6">
          <ContextMenu
            grecord={grecord}
            guestRecord={guestRecord}
            menuOptions={menuOptions}
            setMenuOptions={setMenuOptions}
          />
          {/* <GuestDetails grecord={grecord} guestRecord={guestRecord} /> */}
        </div>
        {menuOptions === 0 && (
          <BrideGrooomDetails
            setFormData={setFormData}
            handleChange={handleChange}
            formData={formData}
            eventDates={eventDates}
          />
        )}
        {menuOptions === 1 && (
          <EventTypes
            setFormData={setFormData}
            handleChange={handleChange}
            formData={formData}
            eventDates={eventDates}
            setEventDates={setEventDates}
          />
        )}
        {menuOptions === 2 && (
          <VenueDetail
            setFormData={setFormData}
            handleChange={handleChange}
            formData={formData}
            setVenueDetail={setVenueDetail}
          />
        )}
        {menuOptions === 3 && (
          <Services
            setFormData={setFormData}
            handleChange={handleChange}
            formData={formData}
          />
        )}
      </div>
      {/* {JSON.stringify(formData)} */}
    </div>
  );
};

export default EventUpdateChild;
