import React, { useState, useEffect } from "react";
import { indian_states } from "../../../components/Data/IndianStates";
import Select from "react-select";
import toast from "react-hot-toast";
import axios from "axios";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import ServiceComponent from "./ServiceComponent";

const Services = ({ handleChange, setFormData, formData }) => {
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [services, setServices] = useState([]);
  const [cityOptions, setCityOptions] = useState();
  const [selectedServices, setSelectedServices] = useState();

  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const indianStates = Object.keys(indian_states);
  const indianStatesOption = indianStates.map((data, index) => {
    return { value: data, label: data };
  });
  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/service/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { state: formData?.state, city: formData?.city },
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response?.data?.services));
        const services = response?.data?.services;
        if (!services.length) {
          toast.error("No Venue at this location");
          setServices([]);
          //setSelectedServices([]);
        } else {
          // toast.success("Venues ");
          setServices(services);
        }

        //
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "GuestAddChild", "/guest/add");
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    // ...existing code...

    // Preselect services based on formData?.selected_services
    if (formData?.selected_services) {
      const selectedServicesIds = JSON.parse(formData?.selected_services);
      setSelectedServices(
        services.filter((service) => selectedServicesIds.includes(service.id))
      );
    } else {
      setSelectedServices([]);
    }
  }, []);

  return (
    <fieldset className="p-3 mx-auto mt-2 ml-5 whitespace-normal border rounded md:w-full">
      <legend className="float-none w-auto p-2 fs-5">Services</legend>
      <div className="max-w-md mx-auto mt-4 mb-5">
        {/* <div className="md:flex md:flex-row">
          <div className="md:w-full">
            <Select
              name="state"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  state: selected.value,
                  city: null,
                });
                setCityOptions(
                  indian_states[selected.value].map((d, i) => {
                    return { value: d, label: d };
                  })
                );
              }}
              placeholder={placeHolderText("Select State")}
              options={indianStatesOption}
              classNamePrefix="my-select"
              className={`w-[${inputWidth}] outline-none h-10`}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              styles={style}
              value={
                formData?.state &&
                indianStatesOption.find((s) => s.value === formData?.state)
              }
            />
          </div>
          <div className="mt-3 md:w-full md:mt-0 md:ml-1">
            <Select
              name="city"
              onChange={dropDownChange}
              options={cityOptions}
              className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select City")}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              value={
                formData?.city && {
                  value: formData?.city,
                  label: formData?.city,
                }
              }
              styles={style}
            />
          </div>
        </div> */}
        <div className="mt-10">
          <ServiceComponent
            services={services}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
        <div className="mt-3">
          <textarea
            className="w-full p-2 mt-4 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            type="text"
            name="special_service_request"
            rows={4}
            onChange={handleChange}
            placeholder="Any Special custom request"
            autoComplete="new-password"
            value={formData?.special_service_request}
          />
        </div>

        <br />
        {/* {JSON.stringify(selectedServices)} */}

        <br />
        {/* {JSON.stringify(formData?.selected_services)} */}
      </div>
    </fieldset>
  );
};

export default Services;
