import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import EventAddChild from "./EventAddChild";
import { useLocation } from "react-router-dom";

const EventAdd = () => {
  let rowdata = useLocation();
  let guestRecord = rowdata.state;
  return (
    <MainTemplate>
      <CardBackground>
        <EventAddChild guestRecord={guestRecord} />
      </CardBackground>
    </MainTemplate>
  );
};

export default EventAdd;
