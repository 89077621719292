import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { indian_states } from "../../../components/Data/IndianStates";

import { useNavigate, NavLink } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const MyAccountChild = ({ userRecord }) => {
  let navigate = useNavigate();

  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [cityOptions, setCityOptions] = useState();
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    email: null,
    mobile: null,
    password: null,
    confirm_password: null,
    state: null,
    city: null,
    address: null,
    pincode: null,
  });

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/user/${user?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const user = response.data.users;
        setFormData({ ...user });
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "UserUpdateChild", "/user/update");
        // console.log(error);
      });
  }, []);

  let passwordRef = useRef();
  let confirmpasswordRef = useRef();

  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const indianStates = Object.keys(indian_states);

  const indianStatesOption = indianStates.map((data, index) => {
    return { value: data, label: data };
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleUserUpdate = () => {
    //e.preventDefault();

    if (formData?.password !== formData?.confirm_password)
      return toast.error("Password don't match");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/user/update/${userRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("User Updated Successfully.");
        navigate("/users");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "UserUpdateChild", "/user/update");
        // console.log(error);
      });
  };

  return (
    <div className="flex justify-center w-full justify">
      <fieldset className="p-2 whitespace-normal border md:w-[600px]">
        <legend className="float-none w-auto p-2 fs-5">My Account</legend>
        <div className="max-w-md mx-auto">
          <div className="mt-4">
            <div className="flex flex-row">
              <div className="w-full">
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  type="text"
                  name="first_name"
                  onChange={handleChange}
                  required
                  placeholder="First Name"
                  autoComplete="new-password"
                  value={formData?.first_name}
                />
              </div>
              <div className="w-full ml-1">
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  type="text"
                  name="last_name"
                  onChange={handleChange}
                  required
                  placeholder="Last Name"
                  autoComplete="new-password"
                  value={formData?.last_name}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="md:flex md:flex-row">
              <div className="md:w-full">
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  disabled
                  placeholder="Email"
                  autoComplete="new-password"
                  value={formData?.email}
                />
              </div>
              <div className="mt-3 md:w-full md:mt-0 md:ml-1">
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  type="text"
                  name="mobile"
                  onChange={handleChange}
                  required
                  placeholder="Mobile"
                  autoComplete="new-password"
                  value={formData?.mobile}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="md:flex md:flex-row">
              <div className="md:w-full">
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                  autoComplete="new-password"
                  ref={passwordRef}
                />
              </div>
              <div className="mt-3 md:w-full md:mt-0 md:ml-1">
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  type="password"
                  name="confirm_password"
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                  ref={confirmpasswordRef}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="md:flex md:flex-row">
              <div className="md:w-full">
                <Select
                  name="state"
                  onChange={(selected, dropdown) => {
                    setFormData({
                      ...formData,
                      state: selected.value,
                      city: null,
                    });
                    setCityOptions(
                      indian_states[selected.value].map((d, i) => {
                        return { value: d, label: d };
                      })
                    );
                  }}
                  placeholder={placeHolderText("Select State")}
                  options={indianStatesOption}
                  classNamePrefix="my-select"
                  className={`w-[${inputWidth}] outline-none h-10`}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                />
              </div>
              <div className="mt-3 md:w-full md:mt-0 md:ml-1">
                <Select
                  name="city"
                  onChange={dropDownChange}
                  options={cityOptions}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Select City")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  value={
                    formData?.city && {
                      value: formData?.city,
                      label: formData?.city,
                    }
                  }
                  styles={style}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="md:flex md:flex-row">
              <div className="md:w-full">
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  type="text"
                  name="address"
                  onChange={handleChange}
                  placeholder="Address here ..."
                  autoComplete="new-password"
                  value={formData?.address}
                />
              </div>
              <div className="mt-3 md:ml-1 md:mt-0">
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  type="text"
                  name="pincode"
                  onChange={handleChange}
                  placeholder="Pincode"
                  autoComplete="new-password"
                  value={formData?.pincode}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between mt-3">
            <div>
              <button
                onClick={() => navigate(-1)}
                className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-700"
              >
                Cancel
              </button>
            </div>
            <div className="ml-auto">
              <button
                className="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-700"
                onClick={handleUserUpdate}
              >
                Update User
              </button>
            </div>
          </div>
          {/* {JSON.stringify(formData)} */}
        </div>
      </fieldset>
    </div>
  );
};

export default MyAccountChild;
