import React, { useEffect, useState, setVenueDetail } from "react";

const VenueCard = ({ data, formData, setFormData, setVenueDetail }) => {
  const [currentCard, setCurrentCard] = useState(0);
  const [checked, setChecked] = useState(false);

  const handleNext = () => {
    setCurrentCard((prev) => (prev === data.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = () => {
    setCurrentCard((prev) => (prev === 0 ? data.length - 1 : prev - 1));
  };

  useEffect(() => {
    if (formData?.venue) {
      setVenueDetail(JSON.parse(formData?.venue));
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    if (checked) {
      setFormData({
        ...formData,
        venue: JSON.stringify(data[currentCard]),
        venue_price: data[currentCard].location_price,
      });
      setVenueDetail(data[currentCard]);
    } else {
      setFormData({ ...formData, venue: null, venue_price: null });
      setVenueDetail(null);
    }
  }, [checked]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="flex justify-between w-full mt-0 mb-4">
        <button
          onClick={handlePrev}
          className={`px-4 py-2 mr-2 text-white rounded ${
            checked ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500"
          }`}
          disabled={checked}
        >
          {"<<"}
        </button>
        <button
          onClick={handleNext}
          className={`px-4 py-2 mr-2 text-white rounded ${
            checked ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500"
          }`}
          disabled={checked}
        >
          {">>"}
        </button>
      </div>
      <div className="w-[600px] overflow-hidden bg-white rounded-lg shadow-lg">
        <div className="flex justify-between px-6 py-4 text-lg text-gray-700 bg-gray-200">
          {data[currentCard].location_name}{" "}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="venue"
              name="venue"
              defaultChecked={checked}
              onChange={() => setChecked(!checked)}
              className="hidden"
            />
            <label
              htmlFor="venue"
              className={`w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 cursor-pointer ${
                checked ? "bg-blue-500" : "bg-white"
              }`}
            >
              {checked && (
                <svg
                  className="text-white fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
              )}
            </label>
            <span className="text-sm text-gray-700">Select Venue</span>
          </div>
        </div>

        <div className="flex justify-between px-6 py-4">
          <div className="text-sm">
            <p>
              <strong>Price: </strong>
              {data[currentCard].location_price}
            </p>
            <p>
              <strong>State: </strong>
              {data[currentCard].state}
            </p>
            <p>
              <strong>City: </strong>
              {data[currentCard].city}
            </p>
            <p className="text-justify">
              <strong>Description: </strong>
              {data[currentCard].location_description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueCard;
