import React, { useEffect, useState } from "react";

const EventView = ({ currentRecord }) => {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    if (currentRecord) {
      setJsonData({ ...currentRecord });
    }
  }, []);

  return (
    <>
      {jsonData !== null ? (
        <div className="p-6 bg-white rounded shadow-md md:p-8 lg:p-10">
          <h2 className="mb-4 text-2xl font-bold">Guest Details</h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <h3 className="mb-2 text-lg font-bold">Guest Information</h3>
              <p>
                <span className="font-semibold">Name:</span>{" "}
                {jsonData.guest_id.first_name} {jsonData.guest_id.last_name}
              </p>
              <p>
                <span className="font-semibold">Mobile:</span>{" "}
                {jsonData.guest_id.mobile}
              </p>
              <p>
                <span className="font-semibold">Email:</span>{" "}
                {jsonData.guest_id.email}
              </p>
              {/* Add more guest information as needed */}
            </div>
            <div>
              <h3 className="mb-2 text-lg font-bold">Event Information</h3>
              <p>
                <span className="font-semibold">Event Name:</span>{" "}
                {jsonData.event_name}
              </p>
              <p>
                <span className="font-semibold">Event Date:</span>{" "}
                {jsonData.event_date.slice(0, 10)}
              </p>
              {/* Add more event information as needed */}
            </div>
          </div>
          <div className="mt-8">
            <h3 className="mb-2 text-lg font-bold">Venue Details</h3>
            <p>
              <span className="font-semibold">Location Name:</span>{" "}
              {JSON.parse(jsonData.venue)?.location_name}
            </p>
            <p>
              <span className="font-semibold">Location Description:</span>{" "}
              {JSON.parse(jsonData.venue)?.location_description}
            </p>
            {/* Add more venue details as needed */}
          </div>
          <div className="mt-8">
            <h3 className="mb-2 text-lg font-bold">Selected Services</h3>
            <ul>
              {JSON.parse(jsonData.selected_services).map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
            {/* Add more selected services details as needed */}
          </div>
          {/* Add more sections or data as needed */}
        </div>
      ) : (
        "Loading ..."
      )}
    </>
  );
};

export default EventView;
