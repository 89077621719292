import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate, NavLink } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const EventTypeAddChild = () => {
  let navigate = useNavigate();

  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [formData, setFormData] = useState({
    event_name: null,
    event_details: null,
  });

  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleEventTypeAdd = (e) => {
    e.preventDefault();

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/etype/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Event Type Created Successfully.");
        navigate("/etype");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "EventTypeAddChild", "/etype/add");
        // console.log(error);
      });
  };

  return (
    <div className="flex justify-center w-full justify">
      <fieldset className="p-2 whitespace-normal border md:w-[600px]">
        <legend className="float-none w-auto p-2 fs-5">Add Event Type</legend>
        <div className="max-w-md mx-auto">
          <form onSubmit={(e) => handleEventTypeAdd(e)}>
            <div className="mt-4">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                type="text"
                name="event_name"
                onChange={handleChange}
                required
                placeholder="Event Type Name"
                autoComplete="new-password"
              />
            </div>
            <div className="mt-4">
              <textarea
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                type="text"
                name="event_details"
                rows={4}
                onChange={handleChange}
                placeholder="Event Description"
                autoComplete="new-password"
              />
            </div>
            <div className="flex items-center justify-between mt-3">
              <div>
                <NavLink
                  to="/etype"
                  className="font-sans text-sm font-light text-gray-400 hover:text-gray-700"
                >
                  <button className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-700">
                    Cancel
                  </button>
                </NavLink>
              </div>
              <div className="ml-auto">
                <button
                  className="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-700"
                  type="submit"
                >
                  Create Event Type
                </button>
              </div>
            </div>
          </form>
        </div>
      </fieldset>
    </div>
  );
};

export default EventTypeAddChild;
