import React, { useState } from "react";
import logoTop from "../../images/top-logo.png";
import avatar from "../../images/avatar-9.jpg";
import { useNavigate } from "react-router-dom";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import RightDropDownMenu from "./RightDropDownMenu";

const TopMenu = () => {
  let navigate = useNavigate();
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [toggleBurgerMenu, setToggleBurgerMenu] = useState(false);
  const [menuState, setMenuState] = useState(true);

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("user-details");
    setUserStatus({ ...userStatus, user: null });
    navigate("/login", { replace: true });
  };

  return (
    <header className="bg-white shadow-xl">
      <nav className="flex justify-between items-center w-[92%] mx-auto ">
        <div className="flex items-center flex-shrink-0 mr-6 text-white">
          <img
            className="w-40 p-2 cursor-pointer "
            style={{ width: "50px" }}
            src={logoTop}
            alt="..."
          />
        </div>
        <div className="flex items-center gap-6">
          <div className="flex mr-4">
            <div className="relative">
              <div
                className="flex items-center justify-center h-10 px-4 text-sm text-gray-600 cursor-pointer hover:text-gray-500 focus:outline-none"
                onClick={() => setMenuState(!menuState)}
                //onMouseEnter={() => setMenuState(!menuState)}
              >
                <img src={avatar} className="rounded-full w-[40px] p-1 mr-1" />
                <span className="block truncate w-27">
                  {userStatus?.user?.name}
                  <span className="block text-xs font-light text-gray-400">
                    {userStatus?.user?.user_type}
                  </span>
                </span>
              </div>
              <RightDropDownMenu
                setMenuState={setMenuState}
                menuState={menuState}
                handleLogout={handleLogout}
              />
            </div>
          </div>
          <svg
            viewBox="0 0 100 100"
            width="12"
            height="12"
            className={`z-10 cursor-pointer ${
              !toggleBurgerMenu && "hidden"
            } mr-2`}
            onClick={() => setToggleBurgerMenu(!toggleBurgerMenu)}
          >
            <line
              x1="10"
              y1="10"
              x2="90"
              y2="90"
              stroke="black"
              stroke-width="10"
            />
            <line
              x1="90"
              y1="10"
              x2="10"
              y2="90"
              stroke="black"
              stroke-width="10"
            />
          </svg>
          <svg
            viewBox="0 0 100 80"
            width="12"
            height="12"
            className={`z-10 text-4xl cursor-pointer mr-2 ${
              toggleBurgerMenu ? "hidden" : "md:hidden "
            }`}
            onClick={() => setToggleBurgerMenu(!toggleBurgerMenu)}
          >
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>
          </svg>
        </div>
      </nav>
    </header>
  );
};

export default TopMenu;
