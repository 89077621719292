import React, { useState } from "react";
import Topbar from "../TopMenu/TopMenu";

const MainTemplate = ({ children }) => {
  const [menuState, setMenuState] = useState(true);

  return (
    <div className="flex flex-col h-screen">
      <Topbar menuState={menuState} setMenuState={setMenuState} />
      <div class="flex-grow bg-gray-100">{children}</div>
    </div>
  );
};

export default MainTemplate;
