import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import EventView from "./EventView";
import jsPDF from "jspdf";
import "jspdf-autotable";

const EventListChild = ({ guestRecord }) => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Guest ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/event/delete/${row._id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "ServiceListChild", "/services");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/event/guest/${guestRecord?._id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.events;
        console.log(JSON.stringify(response.data));
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
            event_name: data?.event_name,
            guest_name:
              data?.guest_id?.first_name + " " + data?.guest_id?.last_name,
            email: data?.guest_id?.email,
            mobile: data?.guest_id?.mobile,
            event_date: data?.event_date,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err.response.data.message);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/event/guest/${guestRecord?._id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          const oldData = response.data.events;
          console.log(JSON.stringify(response.data));
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data?._id,
              event_name: data?.event_name,
              guest_name:
                data?.guest_id?.first_name + " " + data?.guest_id?.last_name,
              email: data?.guest_id?.email,
              mobile: data?.guest_id?.mobile,
              event_date: data?.event_date,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err.response.data.message);
        });
    };
    getDataFromServer();
  }, []);

  const handleDownloadPDF = (row) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Set the document properties and styling
    doc.setFontSize(12);

    // Table headers
    const headers1 = [["Client ", ""]];

    // Table data rows
    const data1 = [
      ["Name", row?.guest_name],
      ["Mobile", row?.mobile],
      ["Email", row?.email],
    ];
    const headers2 = [[`Event Name: ${row?.event_name}`, ""]];
    const data2 = [
      ["Requestor Name", row?.requester_name],
      ["Event Date", row?.event_date?.slice(0, 10)],
      ["Bride Name", row?.bride_name],
      ["Groom's Name", row?.groom_name],
    ];

    const headers3 = [["Events", "Date"]];
    const selected_events_data = JSON.parse(row?.selected_events)?.map((e) => [
      e?.event_name,
      JSON.parse(e?.event_date)?.slice(0, 10),
    ]);
    const data3 = [
      ...selected_events_data,
      ["Special event request", row?.special_event_request],
    ];

    const headers4 = [["Venue", ""]];
    const venue = JSON.parse(row?.venue);
    const data4 = [
      ["Venue", venue?.location_name],
      ["State", row?.state],
      ["City", row?.city],
      ["Description", venue?.location_description],
    ];

    const headers5 = [["Services", ""]];
    const services = JSON.parse(row?.selected_services)?.map((service) => [
      service,
      "",
    ]);
    const data5 = [
      ...services,
      ["Special service request", row?.special_service_request],
    ];

    // Generate the first table using autoTable plugin
    doc.autoTable({
      head: headers1,
      body: data1,
      startY: 20, // Starting position of the first table from the top
    });

    // Generate the second table below the first table
    doc.autoTable({
      head: headers2,
      body: data2,
      startY: doc.lastAutoTable.finalY + 5, // Starting position of the second table below the first table
    });

    // Generate the third table below the second table
    doc.autoTable({
      head: headers3,
      body: data3,
      startY: doc.lastAutoTable.finalY + 5, // Starting position of the third table below the second table
    });

    // Generate the fourth table below the second table
    doc.autoTable({
      head: headers4,
      body: data4,
      startY: doc.lastAutoTable.finalY + 5, // Starting position of the third table below the second table
    });

    // Generate the fourth table below the second table
    doc.autoTable({
      head: headers5,
      body: data5,
      startY: doc.lastAutoTable.finalY + 5, // Starting position of the third table below the second table
    });

    // Save the PDF
    doc.save(`${row.event_name}.pdf`);
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() =>
                  navigate("/event/update", {
                    state: { guest: guestRecord, event: params.row },
                  })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Download PDF">
              <div
                onClick={() => {
                  handleDownloadPDF(params.row);
                }}
              >
                <i
                  className="mdi mdi-download"
                  style={{ fontSize: "15px", color: "blue", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "event_id",
      headerName: "Event ID",
      width: 190,
    },
    {
      field: "event_name",
      headerName: "Event Name",
      width: 190,
    },
    {
      field: "event_date",
      headerName: "Event Date",
      width: 180,
      renderCell: (params) => params?.row?.event_date?.slice(0, 10),
    },
  ];
  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <div className="flex justify-between w-full">
        <h1>Events</h1>
        {/* <button
          className="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-900"
          onClick={() => navigate("/event/add", { state: guestRecord })}
        >
          Add Event
        </button> */}
      </div>

      <div className="flex flex-row p-2 text-white bg-gray-500 rounded">
        <div className="flex items-center ml-2 font-bold">Client Name:</div>
        <div className="flex items-center ml-2">
          {guestRecord?.first_name + " " + guestRecord?.last_name} |
        </div>
        <div className="flex items-center ml-2 font-bold">Phone:</div>
        <div className="flex items-center ml-2">{guestRecord?.mobile} |</div>
        <div className="flex items-center ml-2 font-bold">Email:</div>
        <div className="flex items-center ml-2">{guestRecord?.email}</div>
        <div className="ml-auto">
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 mr-4 text-white bg-red-500 border border-white rounded hover:bg-red-700"
          >
            Back
          </button>
          <button
            onClick={() => navigate("/event/add", { state: guestRecord })}
            className="px-4 py-2 text-white bg-blue-500 border border-white rounded hover:bg-blue-700"
          >
            Add Event
          </button>
        </div>
      </div>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Event Detail</h2>
            <EventView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      {/* {JSON.stringify(data)} */}
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Event Types"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

export default EventListChild;
