import React, { useEffect } from "react";
import { DatePicker } from "antd";
import moment from "moment";

const BrideGrooomDetails = ({
  handleChange,
  setFormData,
  formData,
  eventDate,
}) => {
  useEffect(() => {
    if (formData?.selected_events) {
      function getEarliestDate(dateArrayString) {
        const dateArray = JSON.parse(dateArrayString);

        // Sort the array based on the event_date in ascending order
        dateArray?.sort(
          (a, b) => new Date(a?.event_date) - new Date(b.event_date)
        );

        // Return the earliest date
        return dateArray[0]?.event_date;
      }

      setFormData({
        ...formData,
        event_date: getEarliestDate(formData?.selected_events) || eventDate,
      });
    }
  }, [formData?.selected_events, eventDate]);

  return (
    <fieldset className="p-3 mx-auto mt-2 ml-5 whitespace-normal border rounded md:w-full">
      <legend className="float-none w-auto p-2 fs-5">
        Bride/Groom Details
      </legend>
      <div className="w-full mt-8 mb-8">
        {/* <fieldset className="p-2 whitespace-normal border md:w-[600px] mx-auto">
          <legend className="float-none w-auto p-2 fs-5">Guest Details</legend> */}

        {/* </fieldset> */}
      </div>
      <div className="max-w-md mx-auto mb-5">
        <div className="mt-3">
          <input
            className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            type="text"
            name="event_name"
            onChange={handleChange}
            required
            placeholder="Event Title"
            autoComplete="new-password"
            value={formData?.event_name}
          />
        </div>
        <div className="mt-3">
          <div className="flex flex-row">
            <div className="w-full">
              <input
                className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                type="text"
                name="requester_name"
                onChange={handleChange}
                required
                placeholder="Requestor Name"
                autoComplete="new-password"
                value={formData?.requester_name}
              />
            </div>
            <div className="w-full ml-1">
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="event_date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    event_date: dateString,
                  });
                }}
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
                placeholder="Select Event Date"
                showToday={true}
                value={
                  formData?.event_date &&
                  moment(formData?.event_date, "YYYY-MM-DD")
                }
              />
            </div>
          </div>
        </div>
        <div className="mt-1">
          <div className="flex flex-row">
            <div className="w-full">
              <input
                className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                type="text"
                name="bride_name"
                onChange={handleChange}
                required
                placeholder="Bride's Name"
                autoComplete="new-password"
                value={formData?.bride_name}
              />
            </div>
            <div className="w-full ml-1">
              <input
                className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                type="text"
                name="groom_name"
                onChange={handleChange}
                required
                placeholder="Groom's Name"
                autoComplete="new-password"
                value={formData?.groom_name}
              />
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default BrideGrooomDetails;
