import { useState, useEffect } from "react";
import { DatePicker } from "antd";
import moment from "moment";

const CheckboxList = ({ options, setFormData, formData }) => {
  // Initialize selectedOptions state with formData
  const [initialSelectedOptions] = useState(
    formData?.selected_events
      ? JSON.parse(formData?.selected_events).map((event) => event.event_id)
      : []
  );
  const [initialEventDates] = useState(
    formData?.selected_events ? JSON.parse(formData?.selected_events) : []
  );
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions
  );
  const [eventDates, setEventDates] = useState(initialEventDates);

  const handleCheckboxChange = (optionId) => {
    const isSelected = selectedOptions.includes(optionId);

    if (isSelected) {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter((id) => id !== optionId)
      );

      setEventDates((prevEventDates) =>
        prevEventDates.filter((event) => event.event_id !== optionId)
      );
    } else {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        optionId,
      ]);
    }
  };

  const handleDateChange = (eventId, date) => {
    //const formattedDate = moment(date).format("YYYY-MM-DD");
    setEventDates((prevEventDates) => [
      ...prevEventDates,
      {
        event_id: eventId?.id,
        event_name: eventId?.label,
        event_date: JSON.stringify(date),
      },
    ]);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      selected_events: JSON.stringify(eventDates),
    });
  }, [eventDates]);

  return (
    <div>
      {options?.map((option) => (
        <div
          key={option.id}
          className="flex w-[300px] items-center justify-between mt-5 mb-2 "
        >
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              value={option.id}
              checked={selectedOptions.includes(option.id)}
              onChange={() => handleCheckboxChange(option.id)}
              className="w-5 h-5 text-blue-500 form-checkbox"
            />
            <span>{option.label}</span>
          </label>
          {selectedOptions.includes(option.id) && (
            <div className="ml-auto">
              <DatePicker
                className="w-32"
                onChange={(date) => handleDateChange(option, date)}
                placeholder="Select date"
                disabledDate={(current) => {
                  let customDate = formData?.event_date
                    ? moment(formData?.event_date).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
                value={
                  eventDates?.find((s) => s.event_id === option.id)
                    ?.event_date &&
                  moment(
                    JSON.parse(
                      eventDates?.find((s) => s.event_id === option.id)
                        ?.event_date
                    ),
                    "YYYY-MM-DD"
                  )
                }
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;
