import React from "react";

const EventTypeView = ({ currentRecord }) => {
  const jsonData = {
    event_name: currentRecord?.event_name,
    event_details: currentRecord?.event_details,
  };

  return (
    <div className="flex flex-col overflow-x-hidden overflow-y-hidden">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="border-b border-gray-200 shadow sm:rounded-lg">
            <div className="bg-white divide-y divide-gray-200">
              {Object.keys(jsonData)?.map((key, i) => (
                <div
                  className="grid grid-cols-2 px-6 py-4 whitespace-nowrap md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"
                  key={i}
                >
                  <div className="flex items-center">
                    <div className="text-sm font-medium text-gray-900">
                      {key
                        ?.replace(/_/g, " ")
                        ?.replace(
                          /\w\S*/g,
                          (txt) =>
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                        )}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="text-sm text-gray-500">
                      {jsonData[key]?.toString()}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventTypeView;
