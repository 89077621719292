import React from "react";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
// import TopMenu from "../../components/TopMenu/TopMenu";

const Home = () => {
  return (
    <MainTemplate>
      <CardBackground>DashBoard</CardBackground>
    </MainTemplate>
  );
};

export default Home;
