import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate, NavLink } from "react-router-dom";

import { indian_states } from "../../../components/Data/IndianStates";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const EventLocationAddChild = () => {
  let navigate = useNavigate();

  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [cityOptions, setCityOptions] = useState();
  const [formData, setFormData] = useState({
    location_name: null,
    location_price: null,
    location_description: null,
    state: null,
    city: null,
  });

  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const indianStates = Object.keys(indian_states);

  const indianStatesOption = indianStates.map((data, index) => {
    return { value: data, label: data };
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleServiceRegistration = (e) => {
    e.preventDefault();

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/elocation/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Event locatin Created Successfully.");
        navigate("/elocations");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "EventLocationAddChild", "/elocation/add");
        // console.log(error);
      });
  };

  return (
    <div className="flex justify-center w-full justify">
      <fieldset className="p-2 whitespace-normal border md:w-[600px]">
        <legend className="float-none w-auto p-2 fs-5">Add Venue</legend>
        <div className="max-w-md mx-auto">
          <form onSubmit={(e) => handleServiceRegistration(e)}>
            <div className="w-[350px] md:w-full mt-4">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                type="text"
                name="location_name"
                onChange={handleChange}
                required
                placeholder="Venue Name"
                autoComplete="new-password"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                type="number"
                name="location_price"
                min="1"
                onChange={handleChange}
                onKeyPress={(event) => {
                  if (event.key === "." || event.key === "-") {
                    event.preventDefault();
                  }
                }}
                placeholder="Price"
                autoComplete="new-password"
              />
            </div>
            <div className="mt-3">
              <div className="md:flex md:flex-row">
                <div className="md:w-full">
                  <Select
                    name="state"
                    onChange={(selected, dropdown) => {
                      setFormData({
                        ...formData,
                        state: selected.value,
                        city: null,
                      });
                      setCityOptions(
                        indian_states[selected.value].map((d, i) => {
                          return { value: d, label: d };
                        })
                      );
                    }}
                    placeholder={placeHolderText("Select State")}
                    options={indianStatesOption}
                    classNamePrefix="my-select"
                    className={`w-[${inputWidth}] outline-none h-10`}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={style}
                  />
                </div>
                <div className="mt-3 md:w-full md:mt-0 md:ml-1">
                  <Select
                    name="city"
                    onChange={dropDownChange}
                    options={cityOptions}
                    className={`w-[${inputWidth}] outline-none h-10`}
                    placeholder={placeHolderText("Select City")}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    value={
                      formData?.city && {
                        value: formData?.city,
                        label: formData?.city,
                      }
                    }
                    styles={style}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 mb-4">
              <textarea
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                type="text"
                name="location_description"
                rows={4}
                onChange={handleChange}
                placeholder="Description"
                autoComplete="new-password"
              />
            </div>
            <div className="flex items-center justify-between mt-3">
              <div>
                <NavLink
                  to="/elocations"
                  className="font-sans text-sm font-light text-gray-400 hover:text-gray-700"
                >
                  <button className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-700">
                    Cancel
                  </button>
                </NavLink>
              </div>
              <div className="ml-auto">
                <button
                  className="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-700"
                  type="submit"
                >
                  Create Venue
                </button>
              </div>
            </div>
          </form>
        </div>
      </fieldset>
    </div>
  );
};

export default EventLocationAddChild;
