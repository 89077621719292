import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import GuestAddChild from "./GuestAddChild";

const GuestAdd = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <GuestAddChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default GuestAdd;
