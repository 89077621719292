import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import UsersListChild from "./UsersListChild";

const UsersList = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <UsersListChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default UsersList;
