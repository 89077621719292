import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import CheckboxList from "./CheckboxList";

const EventTypes = ({
  handleChange,
  setFormData,
  formData,
  eventDates,
  setEventDates,
}) => {
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [eventTypes, setEventTypes] = useState();
  //const [eventDates, setEventDates] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    //  setEventDates(JSON.parse(formData?.selected_events));
  }, []);

  useEffect(() => {
    if (!eventTypes) {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/etype/`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          const etypes = response?.data?.etypes;
          setEventTypes(
            etypes?.map((etype) => ({ id: etype._id, label: etype.event_name }))
          );
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
          errorLogApi(error, "GuestAddChild", "/guest/add");
        });
    }
  }, []);

  return (
    <fieldset className="p-3 mx-auto mt-2 ml-5 whitespace-normal border rounded md:w-full">
      <legend className="float-none w-auto p-2 fs-5">Event Types</legend>
      <div className="max-w-md mx-auto mb-5">
        {/* Content here  */}
        <CheckboxList
          options={eventTypes}
          formData={formData}
          setFormData={setFormData}
          eventDates={eventDates}
          setEventDates={setEventDates}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
        <textarea
          className="w-full p-2 mt-4 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
          type="text"
          name="special_event_request"
          rows={4}
          onChange={handleChange}
          placeholder="Any Special custom request"
          autoComplete="new-password"
          value={formData?.special_event_request}
        />
      </div>
    </fieldset>
  );
};

export default EventTypes;
