import axios from "axios";
import toast from "react-hot-toast";
import { decode as base64_decode, encode as base64_encode } from "base-64";

//Sanitization function
//Use case: sanitizeFunction = (input.value, input.name)
//make sure onClick and onChange both handlers are set to handleChange on input
export const sanitizeFunction = (value, input_name) => {
  // const conditionRegex = /([^a-zA-Z0-9:\.\/\(\)\-\s])/g;
  const conditionRegex = /([^a-zA-Z0-9:\-\s])/g;
  const field_values = [
    "password",
    "email",
    "primary_email",
    "secondary_email",
  ];
  if (!field_values.includes(input_name)) {
    if (conditionRegex.test(value)) {
      toast.error("Please Use A-Z, a-z, 0-9 only!");
      return false;
    } else {
      return true;
    }
  }
};

//Error Logging function
// Use case : errorLogFunction = (error, page, route);
export const errorLogFunction = (errorInfo, page, route) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/elog/add`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: {
      error_log: JSON.stringify(errorInfo),
      page: page,
      route: route,
    },
  };
  axios(config)
    .then(function (response) {})
    .catch(function (error) {});
};

//Encrypt Data
export const encryptData = (plain_text) => {
  const encryptedData = base64_encode(plain_text);
  return encryptedData;
};

//Decrypt Data
export const decryptData = (enc_text) => {
  const decryptedData = base64_decode(enc_text);
  return decryptedData;
};

//Data Formatter
export const dateFormatter = (date) => {
  const date_conv = new Date(date);
  const fullyear = date_conv.getFullYear();
  const month = date_conv.getMonth() + 1;
  const day = date_conv.getDate();
  return `${day}/${month}/${fullyear}`;
};

//Placeholder text formatter
export const placeHolderText = (text) => (
  <span className="text-sm text-light">{text}</span>
);

//Bloodgroup
export const bloodgroupOptions = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
  { value: "A1+", label: "A1+" },
  { value: "A1-", label: "A1-" },
  { value: "A2+", label: "A2+" },
  { value: "A2-", label: "A2-" },
  { value: "A1B+", label: "A1B+" },
  { value: "A1B-", label: "A1B-" },
  { value: "A2B+", label: "A2B+" },
  { value: "A2B-", label: "A2B-" },
  { value: "Rhnull+", label: "Rhnull+" },
  { value: "Rhnull-", label: "Rhnull-" },
  { value: "Bombay+", label: "Bombay+" },
  { value: "Bombay-", label: "Bombay-" },
];
