import React, { useState, useEffect } from "react";

const ServiceComponent = ({ services, formData, setFormData }) => {
  const [selected, setSelected] = useState({});
  const [selectedServices, setSelectedServices] = useState(
    formData?.selected_services ? JSON.parse(formData?.selected_services) : []
  );

  const toggleSelect = (service) => {
    setSelected((prev) => ({
      ...prev,
      [service.service]: !prev[service.service],
    }));

    if (selected[service.service]) {
      // Service is currently selected, so we need to remove it
      setSelectedServices((prev) => prev.filter((s) => s !== service.service));
    } else {
      // Service is not currently selected, so we need to add it
      setSelectedServices((prev) => [...prev, service.service]);
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      selected_services: JSON.stringify(selectedServices),
    });
  }, [selectedServices]);

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Service
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Select
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {services.map((service, i) => (
                  <tr key={i}>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {service.service}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {service.price}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={selectedServices.includes(service.service)}
                        onChange={() => toggleSelect(service)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceComponent;
